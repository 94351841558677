.content1{
font-weight: 400;
font-size: 16px;
line-height: 100%;
color: #061938;
margin-top: 10px;
text-align: left;
}
.contentc{
font-weight: 400;
font-size: 16px;
line-height: 100%;

margin-top: 10px;
text-align: left;
}
.contentr{
font-weight: 400;
font-size: 16px;
line-height: 100%;

margin-top: 10px;
text-align: left;
}
.conten{
    margin-top: 20px;
    align-items: center;
}
.center{
    text-align: center;
    margin-top: 20px;
}
.css-o4b71y-MuiAccordionSummary-content {
    justify-content: space-between;
    padding-right: 24px;
    
}
.css-g702pz-MuiTableRow-root {
    display:'flex'
 }
 .makeStyles-tr-48  {
    border: none;
 }

